.carousel {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 100%;  /* Ширина контейнера */
    height: 100%;
}

.slide {
    border-radius: 0.5rem;
    
    object-fit: cover;
    max-width: 100%;
    max-height: 100%; 
    object-fit: contain;
    z-index: 1;
}

@media screen and (min-width: 1200px) {
    .slide {
        height: 500px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
    .slide {
        height: 500px;
    }
} 

@media screen and (max-width: 768px) {
    .slide {
        width: 90%;
    }
} 

.slide-hidden {
    display: none;
}

.arrow {
    position: absolute;
    top: 50%; 
    transform: translateY(-50%) scale(1.8);
    opacity: 85%;
    color: rgb(244, 240, 240);
    filter: drop-shadow(3px 1px 4px #555);
    z-index: 10;
}

.arrow:hover {
    cursor: pointer;
}

.arrow-left {
    left: 2rem;
}

.arrow-right {
    right: 2rem;
}

.hidden {
    visibility: hidden;
}

.indicators {
    
    position: absolute;
    bottom: 1rem;
    z-index: 1;
}

.indicator {
    background-color: rgb(232, 228, 228);
    height: 0.7rem;
    width: 0.6rem;
    border-radius: 90%;
    border: none;
    outline: none;
    box-shadow: 0px 0px 5px #555;
    margin:  0 0.2rem;
    cursor: pointer;
    opacity: 60%;
}

.indicator-inactive {
    background-color: gray;
}