.drawer {
  width: 90%;
  margin-left: 5%;
  border-radius: 16px;
  align-items: center;
  font-size: 50px;
  background-color: #D9D9D9;
  opacity: 85%;
  position: relative;
  justify-content: center;
}

@media screen and (min-width: 1200px) {
  .drawer {
      height: 80%;
      width: 60%;
      margin-left: 20%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .drawer {
      height: 70%;
  }
}

@media screen and (max-width: 767px) {
  .header-box{
    position: absolute;
    top: 30px;
    text-align: center;
  }

  .image-box{
    padding-bottom: 40px;
    height: 30rem;
    position: relative;
  }

  .footer-box{
    position: absolute;
    bottom: 10px;
  }
}

.header-box, .footer-box{
  text-align: center;
}

.image-box {

  width: 100%;
  height: 100%; /* Обеспечим, чтобы .image-box занимал всю высоту */
  display: flex;
  justify-content: center;
  align-items: center;
}

.kpl {
  text-align: right;
  padding-top: 5px;
  size: 30px;
}

.price {
  color: #FF4E00;
  padding-bottom: 10px;
  size: 30px;
  padding-left: 200px;
}
