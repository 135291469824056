.filter-box {
    border: 3px;
    margin: 2rem;
    border-radius: 5px;
    text-align: center;
    border: 0.7px solid #81B225;
    padding: 1rem;
    display: grid;
    background-color: #111111;
}

.menuItem {
    background-color: "#D9D9D9";
    opacity: "85%";
    font-size: "20px";
    text-transform: "uppercase";
  }

  /* .grid{
    text-align: "center";
    padding-top: "4px";
    padding-bottom: "4px";
  } */

/* .button-search{
  background-color: "rgb(180, 192, 188)";
  box-shadow: "2px 2px 7px 1px #79828b";
  border: "1px";
  border-radius: "3px";
  margin: "5px";
  color: "#47494a";
  width: "60%";
  margin-top: "10px";
} */
  
  /* .textField {
    width: "90%",
    "& fieldset": {
      borderRadius: "16px",
          borderColor: "#0E6163"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "#0E6163"
        },
        "& .MuiInputBase-root": {
          color: "#FFFFFF",
          // textTransform: "uppercase"
        }
  } */